.IconCardWrapper {
    @apply max-w-xl;
}
.IconCardList {
    @apply grid grid-cols-4 gap-6;
}
.IconCard {
    @apply flex flex-col items-center justify-end text-xs;
}
.IconCardImg {
    @apply mb-2;
}

.TitleTab {
    @apply flex gap-16;
}
.TitleItem {
    @apply px-4 py-2 font-medium;
    @apply hover:bg-gray-200 hover:cursor-pointer !important;
}
