.embla {
    @apply mx-auto;
    --slide-spacing: 2.5rem;
    --dot-size-large: 2.5rem;
    --dot-size-small: 0.75rem;
    padding-top: 64px;
}
.embla__viewport {
    @apply overflow-hidden relative;
}
.embla__container {
    @apply flex;
    @apply touch-pan-y;
    backface-visibility: hidden;
    margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
    @apply grow-0 shrink-0 basis-1/5;
    @apply min-w-0;
    padding-left: var(--slide-spacing);
}

.embla__slide img {
    @apply rounded-lg border border-gray-300;
}

.embla__controls {
    @apply absolute top-1/2 left-0 right-0 transform -translate-y-1/2;
    @apply flex justify-between items-center;
}

.embla__buttons {
    @apply grid;
    @apply gap-2.5;
    @apply items-center;
    grid-template-columns: repeat(2, 1fr);
}

.embla__dots {
    @apply flex flex-wrap mt-6;
    @apply justify-center;
    @apply items-center;
}

.embla__dot {
    @apply flex;
    @apply bg-transparent;
    @apply touch-manipulation;
    @apply no-underline cursor-pointer border-0;
    @apply p-0 m-0 rounded-full;
    @apply justify-center items-center;
    width: var(--dot-size-large);
    height: var(--dot-size-large);
}

.embla__dot:after {
    @apply flex;
    @apply rounded-full;
    @apply items-center;
    @apply bg-[#D4D4D4];
    width: var(--dot-size-small);
    height: var(--dot-size-small);
    content: '';
}

.embla__dot--selected:after {
    @apply bg-[#4E4E4E];
}
