.CardItem {
    @apply flex flex-col;
    @apply w-full; /* 让 CardItem 占满容器宽度 */
    display: flex;
    flex-direction: column;
    height: 100%;
}

.skeletonWrap {
    @apply overflow-hidden;
    width: 100%; /* 宽度占满容器 */
    height: 230px; /* 固定高度，可以根据需要调整 */
    display: flex;
    justify-content: center;
    align-items: center;
    @apply rounded-lg;
}

.CardContent {
    @apply px-6 py-4;
    @apply flex-1;
    /* @apply border-b border-b-gray-300; */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 让内容靠上对齐 */
    justify-content: flex-start; /* 让内容靠上对齐 */
    /* @apply break-all; */
    /* font-family: Monaco; */
}

.skeletonWrap img {
    @apply md:transition-transform md:duration-500 md:ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 填充父元素并保持比例 */
}

.skeletonWrap .react-loading-skeleton {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 填充父元素并保持比例 */
}

.CardItem:hover .skeletonWrap img {
    @apply md:scale-105;
}

.CardItem:hover .CardContent {
    @apply underline;
}

.Title {
    @apply text-sm capitalize;
}

.Brief {
}

.readMore {
    @apply px-6 py-4;
}
