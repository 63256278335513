.Section4 {
    @apply py-20;
}
.Layout1 {
    @apply grid grid-cols-1 md:grid-cols-2 gap-10;
}
.SampleSection {
    background: #00bab6;
    @apply grid gap-20 mx-auto py-16 px-16;
    @apply md:grid-cols-7;
    @apply xl:max-w-6xl;
}
.SampleIntro {
    @apply flex flex-col justify-center;
    @apply md:col-span-4;
}
.imgWrap {
    @apply content-center;
    @apply md:col-span-3;
}
.SampleLink {
    @apply inline-block;
    @apply bg-white text-blue-800;
    @apply capitalize font-medium;
    @apply px-6 py-3 rounded-md;
    @apply hover:bg-blue-600 hover:text-white hover:opacity-100;
}
