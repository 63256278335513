.Section8 {
    @apply py-20;
}
.IconCardWrapper {
    @apply bg-[#f7f7f7] px-4 py-6;
}
.IconCardList {
    @apply grid grid-cols-4 gap-6;
}
.IconCard {
    @apply flex flex-col items-center justify-end text-xs;
}
.IconCardImg {
    @apply mb-2;
}
