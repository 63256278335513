.RelatedCustoms {
    @apply py-20;
}
.CardWrap {
    @apply grid grid-cols-1 md:grid-cols-4 gap-10;
}

.CardItem {
    @apply flex flex-col;
    @apply w-full; /* 让 CardItem 占满容器宽度 */
    display: flex;
    flex-direction: column;
    height: 100%;
}

.skeletonWrap {
    @apply overflow-hidden border mb-4;
    width: 100%; /* 宽度占满容器 */
    height: 230px; /* 固定高度，可以根据需要调整 */
    display: flex;
    justify-content: center;
    align-items: center;
    @apply rounded-lg;
}

.skeletonWrap img {
    @apply md:transition-transform md:duration-500 md:ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 填充父元素并保持比例 */
}
.CardItem:hover .skeletonWrap img {
    @apply md:scale-105;
}

.Title {
    @apply flex justify-center font-medium;
}

.CardItem:hover .Title {
    @apply underline;
}
